<template>
    <div>
    <ContentHeader title="Data Jurnal Keuangan" url="/data-jurnal-keuangan" subTitle="Tambah Data Jurnal Keuangan" />
     <div class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="card">
                        <div class="card-header row d-flex align-items-center">
                            <h3 class="card-title col-6">Tambah Data Jurnal Keuangan</h3>
                        </div>
                        <div class="card-body px-2">
                            <form class="pl-2" @submit.prevent="prosesJurnalKeuangan">
                                <div class="row">
                                    <div class="col-0 col-lg-2"></div>
                                    <div class="col-12 col-lg-8">
                                        <div class="form-group">
                                            <label>Tanggal</label>
                                            <input type="date" class="form-control" required="" v-model="jurnal_keuangan.tanggal">
                                        </div>
                                        <div class="form-group">
                                            <label>Nomor Bukti</label>
                                            <input type="text" class="form-control" required="" v-model="jurnal_keuangan.nomor_bukti">
                                        </div>
                                        <div class="form-group">
                                            <label>Jurnal Singkat</label>
                                            <multiselect 
                                              v-model="jurnal_singkat_select_value"
                                              deselect-label="Can't remove this value"
                                              track-by="deskripsi"
                                              label="deskripsi"
                                              placeholder=""
                                              :options="jurnal_singkat"
                                              :allow-empty="false"
                                              @update:model-value="generateDataJurnalSingkat"
                                            >
                                            </multiselect>
                                        </div>
                                        <div class="form-group">
                                            <label>Jenis</label>
                                            <input type="text" name="" v-model="jurnal_keuangan.jenis" class="form-control" disabled="">
                                        </div>
                                        <div class="form-group">
                                            <label>Akun Debit</label>
                                            <select class="custom-select form-control" required="" disabled="" v-model="jurnal_keuangan.akun_debit_id">
                                                <option disabled="">{{ label_akun_debit }}</option>
                                                <option v-for="row in akun_keuangan" :key="row.id" :value="row.id">{{ row.kode }} - {{ row.nama }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Akun Kredit</label>
                                            <select class="custom-select form-control" required="" disabled="" v-model="jurnal_keuangan.akun_kredit_id">
                                                <option disabled="">{{ label_akun_kredit }}</option>
                                                <option v-for="row in akun_keuangan" :key="row.id" :value="row.id">{{ row.kode }} - {{ row.nama }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Keterangan</label>
                                            <textarea class="form-control" style="min-height: 100px;" required="" v-model="jurnal_keuangan.keterangan"></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label>Nominal</label>
                                            <input type="text" class="form-control" required="" v-model="jurnal_keuangan.nominal">
                                        </div>
                                        <button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
                                          <div>
                                            Submit
                                          </div>    
                                          <div>
                                            <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                              <g>
                                                <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
                                                <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
                                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                              </g>

                                            </svg>
                                          </div>
                                        </button>
                                    </div>
                                    <div class="col-0 col-lg-2"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import { ref, reactive, onMounted, computed } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import Multiselect from '@suadelabs/vue3-multiselect'

export default{
    components: {
        ContentHeader,
        Multiselect
    },
    setup(){
        const user = computed(() => store.getters['auth/user'])
        const jurnal_keuangan = reactive({
            'cabang_id' : user.value.cabang_id,
            'tanggal' : '',
            'nomor_bukti' : '',
            'jurnal_singkat_id' : '',
            'jenis' : '',
            'akun_debit_id' : '',
            'akun_kredit_id' : '',
            'nominal' : '',
            'keterangan' : ''
        })
        const jurnal_singkat = ref([])
        const akun_keuangan = ref([])
        const label_jurnal_singkat = ref('')
        const label_akun_debit = ref('')
        const label_akun_kredit = ref('')
        const loading = ref(false)
        const jurnal_singkat_select_value = ref('')

        const getJurnalSingkat = async () => {
            let { data } = await axios.get(`api/jurnal_singkat/getAll/${user.value.cabang_id}`)

            if (data != 'kosong') {
                jurnal_singkat.value = data
                label_jurnal_singkat.value = '- Pilih Jurnal Singkat -'
            } else {
                jurnal_singkat.value = []
                label_jurnal_singkat.value = '- Data Kosong -'
            }
        }

        const getAkunKeuangan = async () => {
            let { data } = await axios.get(`api/akun_keuangan/getAll`)

            if (data != 'kosong') {
                akun_keuangan.value = data
                label_akun_debit.value = '- Pilih Akun Debit -'
                label_akun_kredit.value = '- Pilih Akun Kredit -'
            } else {
                akun_keuangan.value = []
                label_akun_debit.value = '- Data Kosong -'
                label_akun_kredit.value = '- Data Kosong -'
            }
        }

        const generateDataJurnalSingkat = () => {
            let data = jurnal_singkat.value.filter((row) => {
                if (row.id == jurnal_singkat_select_value.value.id) {
                    return row
                }
            })

            if (data[0]) {
                data = data[0]

                jurnal_keuangan.jurnal_singkat_id = data.id
                jurnal_keuangan.akun_debit_id = data.akun_debit_id
                jurnal_keuangan.akun_kredit_id = data.akun_kredit_id
                jurnal_keuangan.jenis = data.jenis
            }
        }

        const prosesJurnalKeuangan = () => {
            loading.value = true
            axios.post('api/jurnal_keuangan/create', jurnal_keuangan)
            .then((response) => {
                if (response.data == 'tidak ada budget') {
                    Swal.fire({
                      title: 'Gagal!',
                      text: 'Tidak Ada Budget',
                      icon: 'error',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    loading.value = false    
                } else {
                    Swal.fire({
                      title: 'Berhasil!',
                      text: 'Berhasil Menanbahkan Data',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 1500
                    })  
                    loading.value = false
                    console.log(response)
                    router.push('/data-jurnal-keuangan')    
                }
            })
            .catch((error) => {
                Swal.fire({
                  title: 'Gagal!',
                  text: 'Gagal Menanbahkan Data',
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })  
                loading.value = false
                console.log(error)
            })
        }

        onMounted(() => {
            getJurnalSingkat()
            getAkunKeuangan()
        })

        return { jurnal_keuangan, jurnal_singkat, label_jurnal_singkat, akun_keuangan, label_akun_kredit, label_akun_debit, loading, prosesJurnalKeuangan, generateDataJurnalSingkat, jurnal_singkat_select_value }
    }
}
</script>

<style>
    
</style>